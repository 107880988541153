<template>
    <!-- Salary Card -->
    <a-card :bordered="false" class="widget-2 h-full" style="margin-bottom: 13px; box-shadow: inset;">

        <template #title>

            <i :class="iconClass" aria-hidden="true"></i>

        </template>

        <div style="margin: 13px;">

            <a-descriptions :bordered="false" size="small" :column="1">

                <a-descriptions-item label="Title">{{ title }}</a-descriptions-item>
                <a-descriptions-item label="Type">{{ channel_type }}</a-descriptions-item>
                <a-descriptions-item label="Provider">{{ providerDisplayName }}</a-descriptions-item>

            </a-descriptions>

            <a-button style="width: 100%;" type="primary" @click="viewChannel">View</a-button>


        </div>



    </a-card>
    <!-- / Salary Card -->
</template>

<script>

export default ({
    props: {
        value: {
            type: Number,
            default: 0,
        },
        prefix: {
            type: String,
            default: "+$",
        },
        icon: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        content: {
            type: String,
            default: "",
        },
        channel_provider: {
            type: String,
            default: "",
        },
        channel_type: {
            type: String,
            default: "",
        },
        channel_id: {
            type: String,
            default: "",
        },
    },
    computed: {

        providerDisplayName() {
            if (this.channel_provider == "twilio") {
                return "Twilio";
            } else if (this.channel_provider == "whabiz") {
                return "Whatsapp Business";
            } 
            else {
                return "Unknown";
            }
        },
        iconClass() {
            if (this.channel_type == "voice") {
                return "fa fa-phone icon";
            } else if (this.channel_type == "chat") {
                return "fa fa-whatsapp icon";

            }
            else if (this.channel_type == "whatsapp") {
                return "fa fa-whatsapp icon";

            }
            else if (this.channel_type == "telegram") {
                return "fa fa-telegram icon";
            }
            else if (this.channel_type == "email") {
                return "fa fa-envelope icon";
            }
            else if (this.channel_type == "sms") {
                return "fa fa-envelope icon";
            }
            else {
                return "fa fa-envelope icon";
            }



        }
    },
    methods: {
        viewChannel() {
            console.log("view channel");
            this.$router.push("/channels/" + this.channel_id);
        }
    },
    data() {
        return {
        }
    },
})

</script>